var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.$t(
            "cip.plat.authority.dataScope.title.indexHeadTitle"
          ),
        },
      }),
      _c(
        "grid-head-layout",
        _vm._g(
          { ref: "searchFrom", attrs: { "search-columns": _vm.searchColumns } },
          _vm.onSearchEvent
        )
      ),
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          "table-loading": _vm.loading,
          data: _vm.data,
          permission: _vm.permissionList,
          "before-open": _vm.beforeOpen,
          "before-close": _vm.beforeClose,
        },
        on: {
          "row-del": _vm.rowDel,
          "row-update": _vm.rowUpdate,
          "row-save": _vm.rowSave,
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "selection-change": _vm.selectionChange,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.onLoad,
          "tree-load": _vm.treeLoad,
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function ({ row }) {
              return [
                _vm.permission.data_scope_setting
                  ? _c(
                      "el-button",
                      {
                        staticStyle: {
                          border: "0",
                          "background-color": "transparent !important",
                        },
                        attrs: {
                          type: "text",
                          icon: "el-icon-setting",
                          size: "small",
                          plain: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleDataScope(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "cip.plat.authority.dataScope.btn.scopeConfBtn"
                            )
                          ) + "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "source",
            fn: function ({ row }) {
              return [
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("i", { class: row.source }),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.form,
          callback: function ($$v) {
            _vm.form = $$v
          },
          expression: "form",
        },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title:
              `[${_vm.scopeMenuName}] ` +
              _vm.$t("cip.plat.authority.dataScope.title.confHeadTitle"),
            visible: _vm.drawerVisible,
            direction: _vm.direction,
            "append-to-body": "",
            "custom-class": "wf-drawer",
            "before-close": _vm.handleDrawerClose,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawerVisible = $event
            },
          },
        },
        [
          _c(
            "basic-container",
            [
              _c(
                "avue-crud",
                {
                  ref: "crudScope",
                  attrs: {
                    option: _vm.optionScope,
                    data: _vm.dataScope,
                    page: _vm.pageScope,
                    "table-loading": _vm.scopeLoading,
                    "before-open": _vm.beforeOpenScope,
                  },
                  on: {
                    "row-del": _vm.rowDelScope,
                    "row-update": _vm.rowUpdateScope,
                    "row-save": _vm.rowSaveScope,
                    "search-change": _vm.searchChangeScope,
                    "search-reset": _vm.searchResetScope,
                    "selection-change": _vm.selectionChangeScope,
                    "current-change": _vm.currentChangeScope,
                    "size-change": _vm.sizeChangeScope,
                    "on-load": _vm.onLoadScope,
                  },
                  model: {
                    value: _vm.formScope,
                    callback: function ($$v) {
                      _vm.formScope = $$v
                    },
                    expression: "formScope",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "menuLeft" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            size: "small",
                            icon: "el-icon-delete",
                            plain: "",
                          },
                          on: { click: _vm.handleDeleteScope },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("cip.cmn.btn.delBtn")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }